.mde-map > .leaflet-container {
  height: calc(100vh - 140px);
  width: 100%;
}

.mde-map-mobile > .leaflet-container {
  height: calc(100vh - 140px);
  width: 100%;
}

.home-map > .leaflet-container {
  height: calc(100vh - 130px);
  width: 100%;
}

.home-map-mobile > .leaflet-container {
  height: calc(100vh - 130px);
  width: 100%;
}

.forecast-map > .leaflet-container {
  height: calc(100vh - 220px);
  width: 100%;
}

.forecast-map-mobile > .leaflet-container {
  height: calc(100vh - 290px);
  width: 100%;
}

.leaflet-container {
  height: calc(100vh);
  width: 100%;
}
