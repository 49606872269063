.manual-data-entry-table {
  margin: 2%;
  max-height: calc(100vh - 325px);
  overflow-y: auto;
  overflow-x: hidden;
}

.manual-data-entry-table-header {
  margin-top: 2%;
  margin-bottom: 2%;
}

.manual-data-entry-row {
  border-bottom: 1px lightgrey solid;
}
.manual-data-entry-grid-item {
  align-self: center;
}

.manual-data-entry-batch-actions {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3%;
}
